import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import { SecureApi } from '../../services/Api';
import ImageModal from './components/ImageModal';
import { useParams, useNavigate } from 'react-router-dom';
import ControlBar from './components/ControlBar';
import VideoPlayer from './components/VideoPlayer';
import ImageGrid from './components/ImageGrid';

interface Image {
  id: string;
  src: string;
  videoName: string;
}

const rotations = [0, 45, 90, 135, 180, 225, 270, 315];

const VideoView: React.FC = () => {
  const { folderName } = useParams<{ folderName: string }>();
  const navigate = useNavigate();
  const [folderImages, setFolderImages] = useState<Image[]>([]);
  const [filteredFolderImages, setFilteredFolderImages] = useState<Image[]>([]);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1);
  const [selectedRotations, setSelectedRotations] = useState<number[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const playIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1000);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    return () => {
      if (playIntervalRef.current) {
        clearInterval(playIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!folderName) {
      navigate('/');
      return;
    }

    const fetchFolderData = async () => {
      try {
        const [imageResponse] = await Promise.all([
          SecureApi.get<AxiosResponse<Image[]>>(
            `/v0/data/video-images/${folderName}`,
            'VIDEO_IMAGES_FETCH'
          ),
        ]);

        setFolderImages(imageResponse.data);
        setFilteredFolderImages(imageResponse.data);

        // Create a blob URL for the video
        const url = `${process.env.REACT_APP_SUPABASE_930_MOVE_VIDEOS_BUCKET}/${folderName}.mp4`;
        setVideoUrl(url);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching folder data:', error);
        setIsLoading(false);
      }
    };

    fetchFolderData();

    // Cleanup function to revoke the blob URL
    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
    };
  }, [folderName, navigate]);

  const onBack = () => {
    navigate('/');
  };

  const handleImageClick = (image: Image, index: number) => {
    setSelectedImage(image);
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setSelectedImageIndex(-1);
    stopSlideshow();
  };

  const navigateImage = (direction: 'prev' | 'next') => {
    setSelectedImageIndex((prevIndex) => {
      const totalImages = filteredFolderImages.length;
      let newIndex = prevIndex;

      if (direction === 'next') {
        newIndex = (prevIndex + 1) % totalImages;
      } else if (direction === 'prev') {
        newIndex = (prevIndex - 1 + totalImages) % totalImages;
      }

      setSelectedImage(filteredFolderImages[newIndex]);
      return newIndex;
    });
  };

  const handleDownload = async (image: Image) => {
    try {
      const response = await SecureApi.get<AxiosResponse<Blob>>(
        `/v0/data/download_frame/${image.videoName}/${image.id}`,
        'IMAGE_DOWNLOAD',
        { responseType: 'blob' }
      );
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${image.id.split('.')[0]}_with_data.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading data:', error);
    }
  };

  const startSlideshow = () => {
    if (filteredFolderImages.length === 0) return;
    setIsPlaying(true);
    if (playIntervalRef.current) {
      clearInterval(playIntervalRef.current);
    }
    playIntervalRef.current = setInterval(() => {
      navigateImage('next');
    }, playbackSpeed);
  };

  const stopSlideshow = useCallback(() => {
    setIsPlaying(false);
    if (playIntervalRef.current) {
      clearInterval(playIntervalRef.current);
      playIntervalRef.current = null;
    }
  }, []);

  const toggleSlideshow = useCallback(() => {
    if (isPlaying) {
      stopSlideshow();
    } else {
      startSlideshow();
    }
  }, [isPlaying, startSlideshow, stopSlideshow]);

  const handleSpeedChange = (speed: number) => {
    setPlaybackSpeed(speed);
    if (isPlaying) {
      stopSlideshow();
      startSlideshow();
    }
  };

  const handleRotationFilter = (rotation: number) => {
    let newSelectedRotations: number[];
    if (selectedRotations.includes(rotation)) {
      newSelectedRotations = selectedRotations.filter((r) => r !== rotation);
    } else {
      newSelectedRotations = [...selectedRotations, rotation];
    }
    setSelectedRotations(newSelectedRotations);

    if (newSelectedRotations.length === 0) {
      setFilteredFolderImages(folderImages);
    } else {
      const filtered = folderImages.filter((image) =>
        newSelectedRotations.some((rotation) =>
          image.id.includes(`_rot${rotation}`)
        )
      );
      setFilteredFolderImages(filtered);
    }
  };

  const resetRotationFilter = () => {
    setSelectedRotations([]);
    setFilteredFolderImages(folderImages);
  };

  const handleBatchDownload = async () => {
    try {
      const response = await SecureApi.get<AxiosResponse<Blob>>(
        `/v0/data/batch-download/${folderName}?rotation_filter=${JSON.stringify(selectedRotations)}`,
        'BATCH_DOWNLOAD',
        { responseType: 'blob' }
      );

      if (response.status !== 200) {
        throw new Error(`Unexpected response status: ${response.status}`);
      }

      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${folderName}_batch_download.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error batch downloading data:', error);

      // Display a user-friendly error message
      alert(
        'An error occurred while downloading the batch. Please try again later.'
      );

      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;

        if (axiosError.response && axiosError.response.status === 401) {
          // Handle unauthorized error (e.g., show a login prompt)
          console.log('Unauthorized access - please log in again.');
        } else if (axiosError.response && axiosError.response.status === 500) {
          // Handle server error
          console.log('Server error - please try again later.');
        } else {
          // Handle other types of errors
          console.log('An unexpected error occurred.');
        }
      } else {
        // Handle non-Axios errors
        console.log('An unexpected error occurred.');
      }
    }
  };

  function isAxiosError(error: any): error is AxiosError {
    return error.isAxiosError === true;
  }

  const handleEditorNavigation = () => {
    navigate(`/editor/${folderName}`);
  };

  const formatNumberWithCommas = (num: number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const toTitleCase = (str: string) => {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const renderHeader = () => (
    <div className="text-left my-8">
      <h2 className="text-2xl font-semibold flex items-center">
        <button onClick={onBack} className="mr-2">
          ←
        </button>
        {toTitleCase(folderName || '')}
      </h2>
      <VideoPlayer videoUrl={videoUrl} />
      <p className="mt-2">
        Total frames: {formatNumberWithCommas(filteredFolderImages.length)}
      </p>
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4">
      {renderHeader()}
      <ControlBar
        rotations={rotations}
        selectedRotations={selectedRotations}
        handleRotationFilter={handleRotationFilter}
        resetRotationFilter={resetRotationFilter}
        isPlaying={isPlaying}
        toggleSlideshow={toggleSlideshow}
        playbackSpeed={playbackSpeed}
        handleSpeedChange={handleSpeedChange}
        handleBatchDownload={handleBatchDownload}
        handleEditorNavigation={handleEditorNavigation}
      />
      <ImageGrid
        images={filteredFolderImages}
        handleImageClick={handleImageClick}
      />
      {selectedImage && (
        <ImageModal
          image={selectedImage}
          onClose={closeModal}
          onNavigate={navigateImage}
          onDownload={handleDownload}
          isPlaying={isPlaying}
          toggleSlideshow={toggleSlideshow}
          playbackSpeed={playbackSpeed}
          onSpeedChange={handleSpeedChange}
        />
      )}
    </div>
  );
};

export default VideoView;
