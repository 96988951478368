import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { containerStyle, disableSelectStyle } from './DraggableImage.styles';

interface DraggableImageProps {
  src: string | null;
}

const DraggableImage: React.FC<DraggableImageProps> = ({ src }) => {
  const { size } = useThree();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dimensions, setDimensions] = useState({ width: 400, height: 200 });
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [resizeCorner, setResizeCorner] = useState<string | null>(null);
  const aspectRatio = useRef(2); // Initial aspect ratio (400 / 200 = 2)
  const imageRef = useRef<HTMLImageElement>(null);

  const MAX_WIDTH = 800;
  const MIN_WIDTH = 100;

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.onload = () => {
        aspectRatio.current = img.width / img.height;
        setDimensions({ width: 400, height: 400 / aspectRatio.current });
      };
      img.src = src;
    }
  }, [src]);

  /**
   * Handles mouse down events on the DraggableImage component.
   *
   * @param e - The React.MouseEvent object representing the mouse down event.
   * @returns void
   */
  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (e.metaKey || e.ctrlKey) {
        if (
          e.shiftKey &&
          (e.target as HTMLElement).classList.contains('resize-handle')
        ) {
          setIsResizing(true);
          setResizeCorner((e.target as HTMLElement).dataset.corner || null);
        } else {
          setIsDragging(true);
        }
        setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
        e.stopPropagation();
      }
    },
    [position]
  );

  /**
   * Handles mouse move events on the DraggableImage component.
   *
   * @param e - The MouseEvent object representing the mouse move event.
   * @returns void
   */
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        const newX = e.clientX - dragStart.x;
        const newY = e.clientY - dragStart.y;
        setPosition({ x: newX, y: newY });
        e.stopPropagation(); // Prevent event from bubbling up
        e.preventDefault(); // Prevent default behavior
      } else if (isResizing && resizeCorner) {
        const dx = e.clientX - dragStart.x - position.x;
        const dy = e.clientY - dragStart.y - position.y;

        let newWidth = dimensions.width;

        if (resizeCorner.includes('right')) {
          newWidth = Math.min(
            MAX_WIDTH,
            Math.max(MIN_WIDTH, dimensions.width + dx)
          );
        } else if (resizeCorner.includes('left')) {
          const potentialWidth = Math.max(MIN_WIDTH, dimensions.width - dx);
          newWidth = Math.min(MAX_WIDTH, potentialWidth);
          setPosition((prev) => ({
            ...prev,
            x: prev.x + dimensions.width - newWidth,
          }));
        }

        const newHeight = newWidth / aspectRatio.current;

        setDimensions({ width: newWidth, height: newHeight });
      }
    },
    [
      isDragging,
      isResizing,
      dragStart,
      position,
      dimensions,
      resizeCorner,
      size,
    ]
  );

  /**
   * Handles the mouse up event, resetting the dragging and resizing state.
   *
   * @returns void
   */
  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      setIsDragging(false);
      setIsResizing(false);
      setResizeCorner(null);
      e.stopPropagation();
    },
    [isDragging, isResizing]
  );

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp, isDragging, isResizing]);

  if (!src) return null;

  return (
    <Html
      transform={false}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      }}
    >
      <div style={containerStyle}>
        <div
          data-cy="draggable-image"
          style={{
            ...containerStyle,
            position: 'absolute',
            top: 0,
            left: 0,
            transform: `translate(${position.x}px, ${position.y}px)`,
            width: `${dimensions.width}px`,
            height: `${dimensions.height}px`,
            pointerEvents: 'auto',
            borderRadius: `8px`,
            overflow: 'hidden',
          }}
          onMouseDown={handleMouseDown}
        >
          <img
            src={src}
            alt="Current frame"
            draggable="false"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              ...disableSelectStyle,
            }}
          />
        </div>
      </div>
    </Html>
  );
};

export default DraggableImage;
