import { createClient } from '@supabase/supabase-js';

// Function to get environment variable, checking both process.env and Cypress.env
const getEnvVariable = (key: string): string => {
  if (typeof window !== 'undefined' && (window as any).Cypress) {
    // We're running in Cypress
    return (window as any).Cypress.env(key) || process.env[key] || '';
  }
  // We're running normally
  return process.env[key] || '';
};

const supabaseUrl = getEnvVariable('REACT_APP_SUPABASE_URL');
const supabaseAnonKey = getEnvVariable('REACT_APP_SUPABASE_ANON_KEY');

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Supabase URL and/or Anon Key are missing');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
  },
});
