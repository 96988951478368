import React from 'react';
import './GoogleSignInButton.css';
import { signInWithGoogle } from '../../../../utils/auth';

interface GoogleSignInButtonProps {
  className?: string;
  setShowGlitch: (show: boolean) => void;
  resetCursor: () => void;
  onError: (error: string) => void;
}

const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = ({
  className,
  setShowGlitch,
  resetCursor,
  onError,
}) => {
  const googleLogin = async () => {
    const { url, error } = await signInWithGoogle();

    if (error) {
      onError(error);
    } else if (url) {
      window.location.href = url;
    }

    setShowGlitch(false);
    resetCursor();
  };

  return (
    <button
      onClick={() => googleLogin()}
      className={`google-sign-in-button ${className}`}
    >
      <span className="button-text">Sign in with Google</span>
    </button>
  );
};

export default GoogleSignInButton;
