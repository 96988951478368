import React, { useRef, useEffect, useState } from 'react';
import styles from './FrameTrack.module.css';

interface FrameTrackProps {
  trajectoryId: number;
  currentFrame: number;
  totalFrames: number;
  onFrameChange: (frame: number) => void;
  onCurrentImageChange: (imageSrc: string | null) => void;
  imageSources: string[];
}

const FrameTrack: React.FC<FrameTrackProps> = ({
  trajectoryId,
  currentFrame,
  totalFrames,
  onFrameChange,
  onCurrentImageChange,
  imageSources,
}) => {
  const frameWidth = 82;
  const trackRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [leftmostIndex, setLeftmostIndex] = useState(0);
  const [currentImageSrc, setCurrentImageSrc] = useState<string | null>(null);

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => {
    if (trackRef.current) {
      const scrollPosition = currentFrame * frameWidth;
      trackRef.current.scrollLeft = scrollPosition;
    }
  }, [currentFrame, frameWidth]);

  useEffect(() => {
    const imageSrc = imageSources[currentFrame] || null;
    onCurrentImageChange(imageSrc);
  }, [currentFrame, imageSources, onCurrentImageChange]);

  /**
   * Renders an image for the given frame index.
   *
   * @param {number} trackId - The index of the track.
   * @param {number} index - The index of the frame to render.
   * @returns {React.ReactElement | null} - The rendered image element, or null if the image failed to load.
   */
  const renderFrameImage = (
    trackId: number,
    index: number
  ): React.ReactElement | null => {
    const imageSrc = imageSources[index] || null;
    if (imageSrc) {
      return (
        <img
          key={index}
          src={imageSrc}
          className={styles.frameImage}
          alt={`Frame ${index}`}
        />
      );
    } else {
      console.warn(`Failed to load image for frame ${index}`);
      return null;
    }
  };

  /**
   * Handles the start of a mouse drag event on the frame track.
   * Sets the `isDragging` state to true and updates the current frame
   * based on the mouse position.
   *
   * @param {React.MouseEvent} e - The mouse event object.
   * @returns {void}
   */
  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.target instanceof HTMLImageElement) return;
    setIsDragging(true);
    updateFrameFromMousePosition(e);
  };

  /**
   * Handles the mouse move event when the user is dragging the frame track.
   * If the user is dragging, it updates the current frame based on the mouse position.
   *
   * @param {React.MouseEvent} e - The mouse event object.
   * @returns {void}
   */
  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging) {
      updateFrameFromMousePosition(e);
    }
  };

  /**
   * Handles the end of a mouse drag event on the frame track.
   * Sets the `isDragging` state to false.
   */
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const calculateIndicatorPosition = (frame: number) => {
    if (totalFrames == 0) return '0px';
    const position = 82 * frame + 40;
    return `${position}px`;
  };

  /**
   * Updates the current frame based on the mouse position within the frame track.
   *
   * @param {React.MouseEvent} e - The mouse event object.
   * @returns {void}
   */
  const updateFrameFromMousePosition = (e: React.MouseEvent) => {
    if (trackRef.current) {
      const rect = trackRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      onFrameChange(Math.round((x - 40) / 82) + leftmostIndex);
    }
  };

  useEffect(() => {
    /**
     * Handles the end of a mouse drag event on the frame track.
     * Sets the `isDragging` state to false.
     *
     * @returns {void}
     */
    const handleMouseUp = () => {
      setIsDragging(false);
    };

    /**
     * Handles the scroll event on the frame track and updates the leftmost index.
     *
     * @returns {void}
     */
    const handleScroll = () => {
      if (trackRef.current) {
        const scrollLeft = trackRef.current.scrollLeft;
        const newLeftmostIndex = Math.floor(scrollLeft / frameWidth);
        setLeftmostIndex(newLeftmostIndex);
      }
    };

    document.addEventListener('mouseup', handleMouseUp);

    const trackElement = trackRef.current;
    if (trackElement) {
      trackElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      if (trackElement) {
        trackElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [frameWidth]);

  return (
    <div
      data-cy="frame-track"
      className={styles.frameTrackContainer}
      ref={trackRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
    >
      <div
        className={styles.indicator}
        style={{ left: calculateIndicatorPosition(currentFrame) }}
      />
      <div className={styles.framesContainer}>
        {Array.from({ length: totalFrames }, (_, i) =>
          renderFrameImage(trajectoryId, i)
        )}
      </div>
      <div
        className={styles.numberLine}
        style={{ width: frameWidth * totalFrames }}
      >
        {Array.from({ length: totalFrames }, (_, i) => (
          <span key={i} className={styles.frameNumber}>
            {i}
          </span>
        ))}
      </div>
    </div>
  );
};

export default FrameTrack;
