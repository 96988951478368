import axios, { AxiosRequestConfig } from 'axios';
import { authorizedEvent, unauthorizedEvent } from '../utils/events';
import { executeRecaptcha, refreshAccessToken } from '../utils/auth';

export const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

async function addAuthHeaders(
  config: AxiosRequestConfig,
  recaptchaContext: string
): Promise<AxiosRequestConfig> {
  const email = localStorage.getItem('email');
  const oauthToken = localStorage.getItem('oauthToken');

  if (!email || !oauthToken) {
    throw new Error('Missing email or OAuth token');
  }

  const recaptchaToken = await executeRecaptcha(recaptchaContext);

  const params = new URLSearchParams(config.params || {});
  params.append('recaptcha_token', recaptchaToken);
  params.append('email', email);
  params.append('oauth_token', oauthToken);

  return {
    ...config,
    params,
  };
}

export const SecureApi = {
  async get<T>(
    url: string,
    recaptchaContext: string,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    await refreshAccessToken(() => {});
    const finalConfig = await addAuthHeaders(config, recaptchaContext);
    return Api.get(url, finalConfig);
  },

  async post<T>(
    url: string,
    data: any,
    recaptchaContext: string,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    await refreshAccessToken(() => {});
    const finalConfig = await addAuthHeaders(config, recaptchaContext);
    return Api.post(url, data, finalConfig);
  },
};

// Response interceptor for error logging
Api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error.response);
    if (error.response && error.response.status === 200) {
      window.dispatchEvent(authorizedEvent);
    } else {
      window.dispatchEvent(unauthorizedEvent);
    }
    return Promise.reject(error);
  }
);
