import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PauseIcon from '@mui/icons-material/Pause';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import styles from './ControlPanel.module.css';

interface ControlPanelProps {
  isPlaying: boolean;
  onPlayPause: () => void;
  onReset: () => void;
  trajectories: number[];
  hiddenTrajectories: Set<number>;
  onToggleHide: (trajectoryId: number) => void;
  onDelete: (trajectoryId: number) => void;
  onSelectTrajectory: (trajectoryId: number) => void;
  selectedTrajectoryId: number;
  isHidden: boolean;
  isDraggableImageVisible: boolean;
  onToggleDraggableImage: () => void;
  translation: [number, number, number];
  rotation: [number, number, number];
  onTranslationChange: (axis: number, value: number) => void;
  onRotationChange: (axis: number, value: number) => void;
  rotationStepSize: number;
  translationStepSize: number;
  setRotationStepSize: React.Dispatch<React.SetStateAction<number>>;
  setTranslationStepSize: React.Dispatch<React.SetStateAction<number>>;
  onToggleTrajectory: () => void;
  showTrajectory: boolean;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
  zMin: number;
  zMax: number;
  playbackSpeed: number;
  onPlaybackSpeedChange: (speed: number) => void;
}

const ControlPanel: React.FC<ControlPanelProps> = ({
  isPlaying,
  onPlayPause,
  onReset,
  trajectories,
  hiddenTrajectories,
  onToggleHide,
  onDelete,
  onSelectTrajectory,
  selectedTrajectoryId,
  isHidden,
  isDraggableImageVisible,
  onToggleDraggableImage,
  translation,
  rotation,
  onTranslationChange,
  onRotationChange,
  rotationStepSize,
  translationStepSize,
  setRotationStepSize,
  setTranslationStepSize,
  onToggleTrajectory,
  showTrajectory,
  xMin,
  xMax,
  yMin,
  yMax,
  zMin,
  zMax,
  playbackSpeed,
  onPlaybackSpeedChange,
}) => {
  const [trajectoryNames, setTrajectoryNames] = useState<{
    [key: number]: string;
  }>({});
  const [editingTrajectoryId, setEditingTrajectoryId] = useState<number | null>(
    null
  );

  useEffect(() => {
    // Initialize trajectory names when trajectories change
    const initialNames = trajectories.reduce(
      (acc, id) => {
        acc[id] = `Trajectory ${id + 1}`;
        return acc;
      },
      {} as { [key: number]: string }
    );
    setTrajectoryNames(initialNames);
  }, [trajectories]);

  /**
   * Handles the change event for a translation input field.
   *
   * @param {number} axis - The index of the translation axis (0 for X, 1 for Y, 2 for Z).
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object.
   * @returns {void}
   */
  const handleInputChange =
    (axis: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseFloat(event.target.value);
      if (!isNaN(newValue)) {
        onTranslationChange(axis, newValue);
      }
    };

  /**
   * Handles the change event for a rotation input field.
   *
   * @param {number} axis - The index of the rotation axis (0 for X, 1 for Y, 2 for Z).
   * @returns {(event: React.ChangeEvent<HTMLInputElement>) => void} - The event handler function.
   */
  const handleRotationChange =
    (axis: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseFloat(event.target.value);
      if (!isNaN(newValue)) {
        onRotationChange(axis, newValue);
      }
    };

  /**
   * Handles the change event for a step size input field.
   *
   * @param {React.Dispatch<React.SetStateAction<number>>} setter - The function to update the step size value.
   * @returns {(event: React.ChangeEvent<HTMLInputElement>) => void} - The event handler function.
   */
  const handleStepSizeChange =
    (setter: React.Dispatch<React.SetStateAction<number>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseFloat(event.target.value);
      if (!isNaN(newValue) && newValue > 0) {
        setter(newValue);
      }
    };

  /**
   * Renders an input field for adjusting the step size of a value.
   *
   * @param {number} value - The current value.
   * @param {React.Dispatch<React.SetStateAction<number>>} setter - The function to update the value.
   * @param {number} step - The step size for the input field.
   * @param {number} min - The minimum value for the input field.
   * @returns {JSX.Element} - The rendered input field.
   */
  const renderStepSizeInput = (
    value: number,
    setter: React.Dispatch<React.SetStateAction<number>>,
    step: number,
    min: number
  ) => (
    <input
      type="number"
      step={step}
      min={min}
      value={value}
      onChange={handleStepSizeChange(setter)}
      className={styles.stepSizeField}
    />
  );

  /**
   * Renders a rotation input field for the 3D scene.
   *
   * @param {string} label - The label for the rotation input field.
   * @param {number} axis - The index of the rotation axis (0 for X, 1 for Y, 2 for Z).
   * @param {string} tooltip - The tooltip text for the rotation input field.
   * @returns {JSX.Element} - The rendered rotation input field.
   */
  const renderRotationField = (
    label: string,
    axis: number,
    tooltip: string
  ) => (
    <div className={styles.rotationFieldContainer}>
      <label className={styles.inputFieldLabel} title={tooltip}>
        {label}:{' '}
      </label>
      <input
        type="number"
        step={1}
        min={0}
        max={360}
        value={rotation[axis]}
        onChange={handleRotationChange(axis)}
        className={styles.translationField}
      />
    </div>
  );

  /**
   * Renders a translation input field for the 3D scene.
   *
   * @param {string} label - The label for the translation input field.
   * @param {number} axis - The index of the translation axis (0 for X, 1 for Y, 2 for Z).
   * @param {number} min - The minimum value for the translation input field.
   * @param {number} max - The maximum value for the translation input field.
   * @param {string} tooltip - The tooltip text for the translation input field.
   * @returns {JSX.Element} - The rendered translation input field.
   */
  const renderTranslationField = (
    label: string,
    axis: number,
    min: number,
    max: number,
    tooltip: string
  ) => (
    <div className={styles.translationFieldContainer}>
      <label className={styles.inputFieldLabel} title={tooltip}>
        {label}:{' '}
      </label>
      <input
        type="number"
        step={0.01}
        min={min}
        max={max}
        value={translation[axis]}
        onChange={handleInputChange(axis)}
        className={styles.translationField}
      />
    </div>
  );

  /**
   * Updates the name of a trajectory.
   * @param {number} trajectoryId - The ID of the trajectory to update.
   * @param {string} newName - The new name for the trajectory.
   * @returns {void}
   */
  const handleTrajectoryNameChange = (
    trajectoryId: number,
    newName: string
  ) => {
    setTrajectoryNames((prev) => ({
      ...prev,
      [trajectoryId]: newName,
    }));
  };

  /**
   * Handles double-click event on a trajectory item.
   * @param {number} trajectoryId - The ID of the trajectory being double-clicked.
   * @returns {void}
   */
  const handleDoubleClick = (trajectoryId: number): void => {
    setEditingTrajectoryId(trajectoryId);
  };

  /**
   * Handles the blur event for the trajectory name input.
   * @returns {void}
   */
  const handleNameBlur = (): void => {
    setEditingTrajectoryId(null);
  };

  /**
   * Handles the key press event for the trajectory name input.
   * @param {React.KeyboardEvent<HTMLInputElement>} event - The keyboard event object.
   * @returns {void}
   */
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === 'Enter') {
      setEditingTrajectoryId(null);
    }
  };

  /**
   * Renders the list of trajectories with hide and delete buttons.
   * @returns {JSX.Element} The rendered list of trajectories.
   */
  const renderTrajectoryList = () => (
    <List className={styles.trajectoryList}>
      {trajectories.map((trajectoryId) => (
        <ListItem
          key={trajectoryId}
          className={`${styles.trajectoryItem} ${selectedTrajectoryId === trajectoryId ? styles.selectedTrajectory : ''}`}
          onClick={() => onSelectTrajectory(trajectoryId)}
          onDoubleClick={() => handleDoubleClick(trajectoryId)}
        >
          {editingTrajectoryId === trajectoryId ? (
            <TextField
              value={trajectoryNames[trajectoryId]}
              onChange={(e) =>
                handleTrajectoryNameChange(trajectoryId, e.target.value)
              }
              onBlur={handleNameBlur}
              onKeyDown={handleKeyPress}
              style={{ color: '#000', width: '200px' }}
              variant="standard"
              size="small"
              autoFocus
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant="body2"
                  style={{ color: '#000', width: '200px' }}
                >
                  {trajectoryNames[trajectoryId] ||
                    `Trajectory ${trajectoryId + 1}`}
                </Typography>
              }
            />
          )}
          <IconButton onClick={() => onToggleHide(trajectoryId)} size="small">
            {hiddenTrajectories.has(trajectoryId) ? (
              <VisibilityOffIcon />
            ) : (
              <VisibilityIcon />
            )}
          </IconButton>
          <IconButton
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              onDelete(trajectoryId);
            }}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </ListItem>
      ))}
    </List>
  );

  /**
   * Renders the playback speed selector.
   * @returns {JSX.Element} The rendered playback speed selector.
   */
  const renderPlaybackSpeedSelector = () => (
    <FormControl className={styles.playbackSpeedSelector}>
      <InputLabel id="playback-speed-label">Speed</InputLabel>
      <Select
        labelId="playback-speed-label"
        value={playbackSpeed}
        onChange={(e) => onPlaybackSpeedChange(e.target.value as number)}
        label="Speed"
      >
        <MenuItem value={0.5}>0.5x</MenuItem>
        <MenuItem value={1}>1x</MenuItem>
        <MenuItem value={2}>2x</MenuItem>
        <MenuItem value={4}>4x</MenuItem>
      </Select>
    </FormControl>
  );

  /**
   * Renders the button to toggle the visibility of the DraggableImage.
   * @returns {JSX.Element} The rendered toggle button.
   */
  const renderToggleDraggableImageButton = () => (
    <IconButton
      onClick={onToggleDraggableImage}
      title={isDraggableImageVisible ? 'Hide Image' : 'Show Image'}
    >
      {isDraggableImageVisible ? <ImageIcon /> : <ImageNotSupportedIcon />}
    </IconButton>
  );

  /**
   * Renders the button to toggle the visibility of the 3D trajectory.
   * @returns {JSX.Element} The rendered toggle button.
   */
  const renderToggleTrajectoryButton = () => (
    <IconButton
      onClick={onToggleTrajectory}
      title={showTrajectory ? 'Hide Trajectory' : 'Show Trajectory'}
    >
      {showTrajectory ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </IconButton>
  );

  return (
    <div data-cy="control-panel" className={styles.controlsContainer}>
      <div className={styles.rotationContainer}>
        <label className={styles.sectionLabel}>
          rotation:{' '}
          {renderStepSizeInput(rotationStepSize, setRotationStepSize, 0.1, 0.1)}
        </label>
        <div className={styles.rotationValues}>
          {renderRotationField('X', 0, 'cmd + up/down')}
          {renderRotationField('Y', 1, 'cmd + left/right')}
          {renderRotationField('Z', 2, 'shift + cmd + left/right')}
        </div>
      </div>
      <div className={styles.translationContainer}>
        <label className={styles.sectionLabel}>
          translation:{' '}
          {renderStepSizeInput(
            translationStepSize,
            setTranslationStepSize,
            0.1,
            0.1
          )}
        </label>
        <div className={styles.translationCoordinates}>
          {renderTranslationField('X', 0, xMin, xMax, 'left/right')}
          {renderTranslationField('Y', 1, yMin, yMax, 'shift + up/down')}
          {renderTranslationField('Z', 2, zMin, zMax, 'up/down')}
          <Toolbar className={styles.toolbarContainer}>
            <IconButton onClick={onPlayPause} className={styles.playButton}>
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <IconButton onClick={onReset} className={styles.resetButton}>
              <RestartAltIcon />
            </IconButton>
            {renderPlaybackSpeedSelector()}
            {renderToggleDraggableImageButton()}
            {renderToggleTrajectoryButton()}
          </Toolbar>
        </div>
      </div>
      {renderTrajectoryList()}
    </div>
  );
};

export default ControlPanel;
