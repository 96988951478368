import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../../../utils/supabase';
import { useAuth, AuthContextType } from '../../../../hooks/useAuth';
import { verifyAccess } from '../../../../utils/auth';

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const { login, logout } = useAuth() as AuthContextType;

  useEffect(() => {
    const handleAuthCallback = async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();

      if (error) {
        console.error('Error getting session:', error);
        setTimeout(() => navigate('/login'), 3000);
        return;
      }

      if (session && session.user) {
        const hasAccess = await verifyAccess(session.user);
        localStorage.setItem('email', session.user.email || '');
        localStorage.setItem('oauthToken', session.access_token || '');
        localStorage.setItem('refreshToken', session.refresh_token || '');

        if (hasAccess) {
          await login();
          setTimeout(() => navigate('/'), 1000);
        } else {
          await logout();
          return;
        }
      } else {
        setTimeout(() => navigate('/login'), 3000);
      }
    };

    handleAuthCallback();
  }, [navigate, login]);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
    </div>
  );
};

export default AuthCallback;
