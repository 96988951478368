import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

interface Image {
  id: string;
  src: string;
  videoName: string;
}

interface ImageModalProps {
  image: Image;
  onClose: () => void;
  onNavigate: (direction: 'prev' | 'next') => void;
  onDownload: (image: Image) => void;
  isPlaying: boolean;
  toggleSlideshow: () => void;
  playbackSpeed: number;
  onSpeedChange: (speed: number) => void;
}

const ImageModal: React.FC<ImageModalProps> = ({
  image,
  onClose,
  onNavigate,
  onDownload,
  isPlaying,
  toggleSlideshow,
  playbackSpeed,
  onSpeedChange,
}) => {
  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      data-cy="image-modal"
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      onClick={handleOutsideClick}
    >
      <div className="relative max-w-4xl max-h-full bg-white rounded-2xl overflow-hidden">
        <button
          data-cy="close-image-modal"
          onClick={onClose}
          className="absolute top-4 right-4 text-black text-3xl font-bold z-10 cursor-pointer w-12 h-12 flex items-center justify-center rounded-full hover:bg-white hover:bg-opacity-25 transition-all duration-200"
        >
          ×
        </button>
        <button
          onClick={() => onNavigate('prev')}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-4xl font-bold z-10 cursor-pointer w-12 h-12 flex items-center justify-center rounded-full hover:bg-white hover:bg-opacity-25 transition-all duration-200"
        >
          &#8249;
        </button>
        <button
          onClick={() => onNavigate('next')}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-4xl font-bold z-10 cursor-pointer w-12 h-12 flex items-center justify-center rounded-full hover:bg-white hover:bg-opacity-25 transition-all duration-200"
        >
          &#8250;
        </button>
        <button
          onClick={() => onDownload(image)}
          className="absolute bottom-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition-colors duration-200 z-10"
        >
          Download
        </button>
        <div className="absolute bottom-4 left-4 flex items-center gap-2">
          <button
            onClick={toggleSlideshow}
            className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition-colors duration-200 z-10"
          >
            {isPlaying ? 'Pause' : 'Play'}
          </button>
          <select
            value={playbackSpeed}
            onChange={(e) => onSpeedChange(Number(e.target.value))}
            className="bg-white text-black px-2 py-1 rounded-full z-10"
          >
            <option value={70}>0.07s</option>
            <option value={150}>0.15s</option>
            <option value={500}>0.5s</option>
            <option value={1000}>1s</option>
          </select>
        </div>
        <TransformWrapper>
          <TransformComponent>
            <img
              src={image.src}
              alt={image.id}
              className="max-w-full max-h-full rounded-lg"
            />
          </TransformComponent>
        </TransformWrapper>
      </div>
    </div>
  );
};

export default ImageModal;
